<!--userInfo-->
<template>
  <div class="frame common-frame dark">
    <div class="user-info">
      <div class="user-head">
        <div class="user-head-img">
          <img :src="pic" alt="">
        </div>
        <el-upload class="avatar-uploader" style="margin-top: 16px;" :show-file-list="false" action="#"
          :http-request="handleAvatarSuccess">
          <el-button>修改头像</el-button>
        </el-upload>
      </div>
      <div class="user-content">
        <div class="user-name">
          <h1 v-show="!isEdit">{{ name }}</h1>
          <el-input v-show="isEdit" size="large" v-model="user.nickname"
            style="width: 300px;font-size:32px;height: 40px;"></el-input>
          <i class="icomoon-pencil" v-show="!isEdit" @click="isEdit = true"></i>
          <i class="icomoon-checkmark" v-show="isEdit" @click="changeNickName()"></i>
        </div>
        <div class="user-bar">
          <div class="user-icon" style="background: rgb(180, 100, 200);">
            <img src="~assets/images/icons/phone.svg" alt="">
          </div>
          <div class="bar-content" v-if="user.phone">
            <b>手机号已绑定（{{user.phone}}）</b>
            <span>您可以享受手机相关的安全及提醒服务</span>
          </div>
          <div class="bar-content" v-else>
            <b>手机号未绑定</b>
            <span>您可以使用手机号来保障您的账号安全</span>
          </div>
          <div class="bar-btn" @click="showPhone(1)">修改手机</div>
        </div>
        <div class="user-bar">
          <div class="user-icon" style="background: rgb(100, 150, 200);">
            <img src="~assets/images/icons/mail.svg" alt="">
          </div>
          <div class="bar-content" v-if="user.email">
            <b>邮箱已绑定（{{user.email}}）</b>
            <span>您可以享受邮箱相关的安全及提醒服务</span>
          </div>
          <div class="bar-content" v-else>
            <b>邮箱未绑定</b>
            <span>您可以使用邮箱来保障您的账号安全</span>
          </div>
          <div class="bar-btn" @click="showPhone(2)">绑定邮箱</div>
        </div>
        <div class="user-bar">
          <div class="user-icon" style="background: rgb(200, 160, 100);">
            <img src="~assets/images/icons/password.svg" alt="">
          </div>
          <div class="bar-content">
            <b>修改密码</b>
            <span>您可以修改密码来保障您账号的安全</span>
          </div>
          <div class="bar-btn" @click="showPhone(3)">修改密码</div>
        </div>
        <div class="user-bar">
          <div class="user-icon" style="background: rgb(140, 200, 100);">
            <img src="~assets/images/icons/cog.svg" alt="">
          </div>
          <div class="bar-content">
            <b>通用配置</b>
            <div class="left" style="margin: 8px 0 0;">
              <div class="insert m-0">
                <div class="attr colon color-gray">吸附距离</div>
                <div class="val">
                  <div class="left" style="padding: 5px 5px 5px 0;">
                    <el-input placeholder="" v-model="target.adsorption"></el-input>
                  </div>
                  <div class="word color-gray">px</div>
                </div>
              </div>
              <div class="insert m-0">
                <div class="attr colon color-gray">吸附距离</div>
                <div class="val">
                  <div class="word color-gray">{{user.adsorption}}px</div>
                </div>
              </div>
            </div>
          </div>
          <div class="bar-btn" @click="changeAdsorption()">编辑</div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <window :title="target.title" class="dark" windowId="bind-phone" v-if="target.windowCode == 1" @windowHide="bindTargetClose()">
        <template #body>
          <div class="line pd-20" v-if="target.type == 1">
            <div class="insert-h">
              <div class="attr">手机号</div>
              <div class="val">
                <el-input type="text" placeholder="请输入手机号" v-model="target.newData"></el-input>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="text" placeholder="请输入验证码" v-model="target.newCode"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationNewCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="line pd-20" v-if="target.type == 2">
            <div class="insert-h">
              <div class="attr">邮箱</div>
              <div class="val">
                <el-input type="text" placeholder="请输入邮箱" v-model="target.newData"></el-input>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="text" placeholder="请输入验证码" v-model="target.newCode"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationNewCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>
          <el-form ref="form" class="line pd-20" :model="target" :rules="rules" v-if="target.type == 3">
            <div class="insert-h">
              <div class="attr">原始密码</div>
              <div class="val">
                <el-form-item prop="old" class="line">
                  <el-input type="password" v-model="target.oldData" placeholder="请输入原始密码"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">新密码</div>
              <div class="val">
                <el-form-item prop="new" class="line">
                  <el-input type="password" v-model="target.newData" placeholder="新密码由6位以上字符组成"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">重复密码</div>
              <div class="val">
                <el-form-item prop="repeat" class="line">
                  <el-input type="password" v-model="target.repeat" placeholder="重复密码必须与新密码一致"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </template>
        <template #footer>
          <div class="line pd-20 align-right">
            <el-button @click="bindTargetClose()">取消</el-button>
            <el-button type="primary" @click="finish()">确定</el-button>
          </div>
        </template>
      </window>


      <window :title="target.title" class="dark" windowId="change-phone" v-if="target.windowCode == 2"
        @windowHide="bindTargetClose()">
        <template #body>
          <div class="line pd-20" v-if="target.step == 0 && (target.type == 1 || target.isPhone == 1)">
            <div class="insert-h">
              <div class="attr">当前已绑定的手机号：<b style="color:rgba(30, 170, 255, 1) ;">{{user.phone}}</b></div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="patextssword" placeholder="请输入验证码" v-model="target.code"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="line pd-20" v-if="target.step == 1 && target.type == 1">
            <div class="insert-h">
              <div class="attr">新手机号</div>
              <div class="val">
                <el-input type="text" placeholder="请输入新手机号" v-model="target.newData"></el-input>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="text" placeholder="请输入验证码" v-model="target.newCode"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationNewCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="line pd-20" v-if="target.step == 0 && (target.type == 2 || target.isPhone == 2)">
            <div class="insert-h">
              <div class="attr">当前已绑定的邮箱：<b style="color:rgba(30, 170, 255, 1) ;">{{user.email}}</b></div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="patextssword" placeholder="请输入验证码" v-model="target.code"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="line pd-20" v-if="target.step == 1 && target.type == 2">
            <div class="insert-h">
              <div class="attr">新邮箱</div>
              <div class="val">
                <el-input type="text" placeholder="请输入新邮箱" v-model="target.newData"></el-input>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">验证码</div>
              <div class="val">
                <el-input type="text" placeholder="请输入验证码" v-model="target.newCode"></el-input>
                <div style="width:120px;margin-left:8px;">
                  <el-button type="primary"
                          v-if="isShowBtn"
                          :plain="emailDis"
                          :disabled="emailDis"
                          style="width: 100%"
                          @click="verificationNewCode()">发送验证码</el-button>
                  <el-button type="info"
                          v-if="!isShowBtn"
                          :plain="true"
                          :disabled="true"
                          style="width: 100%">{{times}}</el-button>
                </div>
              </div>
            </div>
          </div>

          <el-form ref="form" class="line pd-20" :model="target" :rules="rules" v-if="target.step == 1 && target.type == 3">
            <div class="insert-h">
              <div class="attr">原始密码</div>
              <div class="val">
                <el-form-item prop="old" class="line">
                  <el-input type="password" v-model="target.oldData" placeholder="请输入原始密码"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">新密码</div>
              <div class="val">
                <el-form-item prop="new" class="line">
                  <el-input type="password" v-model="target.newData" placeholder="新密码由6位以上字符组成"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="insert-h">
              <div class="attr">重复密码</div>
              <div class="val">
                <el-form-item prop="repeat" class="line">
                  <el-input type="password" v-model="target.repeat" placeholder="重复密码必须与新密码一致"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </template>
        <template #footer>
          <div class="line pd-20 align-right">
            <el-button @click="bindTargetClose()">取消</el-button>
            <el-button type="primary" @click="next()" v-if="target.step == 0">下一步</el-button>
            <el-button type="primary" @click="finish()" v-if="target.step == 1">完成</el-button>
          </div>
        </template>
      </window>
    </teleport>
    <verification :verify-show="verifyObj.show"
                  @cancel="verificationCancel"
                  @confirm="verificationConfirm"
                  v-if="target.windowCode == 2 && target.step == 0"
                  ref="verify" />
    <verification :verify-show="verifyObj.show"
                  v-else
                  @cancel="verificationCancel"
                  @confirm="sendNewCode"
                  ref="verify" />
  </div>
</template>
<script>
import Window from "components/common/forms/Window";
import { useStore } from 'vuex';
import { ref, onMounted, computed, getCurrentInstance, watch } from 'vue';
import Verification from 'components/content/frame/login/childComps/Verification'
export default {
  name: 'userInfo',
  components: {
    Window,
    Verification
  },
  setup (props, { }) {
    const store = useStore();
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const $confirm = getCurrentInstance().appContext.config.globalProperties.$confirm;
    const $message = getCurrentInstance().appContext.config.globalProperties.$message;

    const defaultHead = require("assets/images/frame/default-head.svg");
    let user = ref({ nickname: '' }), isEdit = ref(false), target = ref({
      type: 0,
      windowCode: 0,
      step: 0, 
      title: '',
      isPhone: 0,
      oldData: '',
      data: '',
      code: '',
      newData: '',
      newCode: '',
      repeat: '',
      adsorption: 0
    }), isShowBtn = ref(true), emailDis = ref(false), verifyObj = ref({
      show: false,
      isSend: false
    }), times = ref(0), timer = ref(null);
    const rules= {
      oldData: [{ required: true, message: '原始密码不能为空', trigger: 'blur' }],
      newData: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
      repeat: [{ required: true, message: '重复输入密码不能为空', trigger: 'blur' }],
    };

    const verify = ref(null);
    let name = computed(() => {
      return user.value.nickname;
    });
    let pic = computed(() => {
      return user.value.icon != '-1' ? '/file/' + user.value.icon : defaultHead;
    });

    const changeUserIcon = () => {
      $api.auth.changeIcon({
        id: user.value.id,
        icon: user.value.icon
      }).then(res => {
        $message.success('修改成功');
        let info = store.state.user;
        info.userInfo = res;
        store.commit("setUserInfo", info);
        user.value = res;
      })
    };

    const changeNickName = () => {
      $api.auth.changeNickName({
        id: user.value.id,
        nickname: user.value.nickname
      }).then(res => {
        $message.success('修改成功');
        let info = store.state.user;
        info.userInfo = res;
        store.commit("setUserInfo", info);
        user.value = res;
        isEdit.value = false;
      })
    };

    const showPhone = (type) => {
      target.value = {
        ...target.value,
        step: 0,
        windowCode: 0,
        newData: '',
        newCode: '',
        isPhone: 0,
        type: type,
      }
      switch (type) {
        case 1:
          if(user.value.phone) {
            target.value.windowCode = 2;
            target.value.isPhone = 1;
            target.value.data = user.value.phone;
            target.value.title = '修改手机号';
          } else {
            target.value.windowCode = 1;
            target.value.title = '绑定手机号';
          }
          break;
        case 2:
          if(user.value.email) {
            target.value.windowCode = 2;
            target.value.isPhone = 2;
            target.value.data = user.value.email;
            target.value.title = '修改邮箱';
          } else {
            target.value.windowCode = 1;
            target.value.title = '绑定邮箱';
          }
          break;
        case 3:
          target.value.title = '修改密码';
          if(user.value.email || user.value.phone) {
            
            target.value.windowCode = 2;
            target.value.isPhone = user.value.phone ? 1 : (user.value.email ? 2 : 0)
            target.value.data = user.value.phone ? user.value.phone : (user.value.email ? user.value.email : null)
          } else {
            target.value.windowCode = 1;
          }
          break;
        default:
          break;
      }
    };

    const next = () => {
      $api.auth.validateMsgCode({
        id: user.value.id,
        code: target.value.code,
        type: target.value.isPhone,
        smsType: 3
      }).then(data => {
        if(data) {
          times.value = 0;
          isShowBtn.value = true;
          clearInterval(timer.value);
          target.value.step ++;
        }
      })
      
    };

    const handleAvatarSuccess = (data) => {
      $api.file.uploadFile(data.file).then((res) => {
        user.value.icon = res.id;
        changeUserIcon();
      })
    };

    const finish = () => {
      if(target.value.type == 3) {
        changePassword();
      }else {
        $api.auth.codeValidate({
          phone: target.value.newData,
          code: target.value.newCode,
          type: target.value.isPhone == 0 ? target.value.type: target.value.isPhone,
          smsType: 3
        }).then(data => {
          if(data) {
            times.value = 0;
            isShowBtn.value = true;
            clearInterval(timer.value);
            let args = {
              id: user.value.id
            }
            if(target.value.type == 1) {
              args.phone = target.value.newData;
            }else if(target.value.type == 2) {
              args.email = target.value.newData;
            }
            $api.auth.changePhoneOrMail(args).then(res => {
              let info = store.state.user;
              info.userInfo = res;
              store.commit("setUserInfo", info);
              user.value = res;
              $message.success('修改成功');
              bindTargetClose();
            })
            
          }
        })
      }
    };

    const changePassword = () => {
      $api.auth.changePass({
        oldPass: target.value.oldData,
        newPass: target.value.newData
      }).then(data => {
        $message.success('修改成功');
        bindTargetClose();
      })
    };

    const bindTargetClose = () => {
      target.value.windowCode = 0;
    };

    const verificationCancel = () =>  {
      verifyObj.value.show = false;
      verify.value.reset();
    };

    const verificationConfirm = () => {
      sendCode();
    };

    const sendCode = () => {
      times.value = 60;
      isShowBtn.value = false;
      let args = {
        id: user.value.id,
        type: target.value.isPhone
      };
      target.value.code = "";
      $api.auth.sendMsg(args).then(data => {
          if(target.value.type == 1) {
            $message.success("手机验证码发送成功，请注意查收");
          }else {
            $message.success("邮箱验证码发送成功，请注意查收");
          }
          timer.value = setInterval(() => {
            times.value--;
            if (times.value == 0) {
              isShowBtn.value = true;
              clearInterval(timer.value);
            }
          }, 1000);
        }, err => {
          $message.error(err.msg);
          isShowBtn.value = true;
          clearInterval(timer.value);
        })
    };

    const sendNewCode = () => {
      times.value = 60;
      isShowBtn.value = false;
      if (target.value.type == 1) {
        $api.auth.sendMessage({
          type: 3,
          phone: target.value.newData
        }).then(data => {
          $message.success("手机验证码发送成功，请注意查收");
          timer.value = setInterval(() => {
            times.value--;
            if (times.value === 0) {
              isShowBtn.value = true;
              clearInterval(timer.value);
            }
          }, 1000);
        }, err => {
          $message.error(err.msg);
          isShowBtn.value = true;
          clearInterval(timer.value);
        })
      } else if (target.value.type == 2){
        $api.auth.sendMailCode({
          type: 3,
          mail: target.value.newData
        }).then(data => {
          $message.success("邮箱验证码发送成功，请注意查收");
          timer.value = setInterval(() => {
            times.value--;
            if (times.value === 0) {
              isShowBtn.value = true;
              clearInterval(timer.value);
            }
          }, 1000);
        }, err => {
          $message.error(err.msg);
          isShowBtn.value = true;
          clearInterval(timer.value);
        })
      }
    };

    const verificationCode = () => {
      if(target.value.type == 1 && !target.value.data) {
        $message.error("请先输入手机号");
        return;
      }else if(target.value.type == 2 && !target.value.data){
        $message.error("请先输入邮箱");
        return;
      }
      verifyObj.value.show = true;
      verify.value.generatorImage();
    };

    const verificationNewCode = () => {
      if(target.value.type == 1 && !target.value.newData) {
        $message.error("请先输入手机号");
        return;
      }else if(target.value.type == 2 && !target.value.newData){
        $message.error("请先输入邮箱");
        return;
      }
      verifyObj.value.show = true;
      verify.value.generatorImage();
    };

    const changeAdsorption = () => {
      $api.auth.updateUserConfig({
        userId: user.value.id,
        adsorption: target.value.adsorption
      }).then(data => {
        $message.success('操作成功');
        let info = store.state.user;
        info.userInfo.adsorption = target.value.adsorption;
        store.commit("setUserInfo", info);
        user.value = info.userInfo;
        target.value.adsorption = 0;
      })
    };

    onMounted(() => {
      user.value = JSON.parse(JSON.stringify(store.state.user ? store.state.user.userInfo : { nickname: '' }));
    })

    watch(() => store.state.user, (n, o) => {
      if(n) {
        user.value = JSON.parse(JSON.stringify(n.userInfo));
      }
      
    })

    return {
      user,
      isEdit,
      name,
      pic,
      target,
      rules,
      isShowBtn,
      emailDis,
      verify,
      verifyObj,
      times,

      changeNickName,
      bindTargetClose,
      handleAvatarSuccess,
      showPhone,
      next,
      finish,
      verificationCancel,
      verificationConfirm,
      verificationCode,
      verificationNewCode,
      sendNewCode,
      changeAdsorption
    }
  }
};
</script>
<style lang='scss' scoped>
.user-info {
  width: 100%;
  float: left;
  padding: 32px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .user-head {
    width: 200px;
    margin-right: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .user-head-img {
      width: 200px;
      height: 200px;
      border: 1px solid rgba(255, 255, 255, .2);
      padding: 1px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .user-content {
    width: 1%;
    flex: 1;

    .user-name {
      width: 100%;
      height: 40px;
      float: left;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 32px;

      h1 {
        font-size: 32px;
        line-height: 40px;
        color: #fff;
        margin: 0;
      }

      i {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: rgba(255, 255, 255, .4);
        transition: all .1s linear;
        font-size: 14px;
        margin-left: 16px;

        &:hover {
          color: #fff;
        }
      }
    }

    .user-bar {
      width: 100%;
      padding: 16px 0;
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      float: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .user-icon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .bar-content {
        width: 1%;
        flex: 1;

        b,
        span {
          font-size: 14px;
          width: 100%;
          float: left;
          line-height: 1.5;
        }

        b {
          font-weight: bold;
        }

        span {
          color: rgba(255, 255, 255, .6);
        }
      }

      .bar-btn {
        width: 100px;
        height: 32px;
        padding: 0 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255, 255, 255, .2);
        border-radius: 16px;
        cursor: pointer;
        color: rgba(255, 255, 255, .8);
        transition: all .1s linear;

        &:hover {
          background: rgba(0, 130, 255, 1);
          color: #fff;
        }

      }
    }
  }
}

.color-gray {
  color: rgba(255, 255, 255, .6);
  min-width: auto !important;
  padding-left: 0 !important;
}
</style>